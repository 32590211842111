import React, {useState} from 'react';
import MulProductPickerModal from '../../../../../../components/mulProductPickerModal';
import update from 'immutability-helper';
import styled from 'styled-components';

const ProductPicker = styled.div`
  .mockInputContainer-error {
    box-shadow: 0px 0px 0px 2px #458fff;
  }
`

const CollectionModal = (props) => {
  const {
    state,
    setState,
    type,
    host,
    shop,
    token,
    index,
    setProductIndex,
    inclusionOption,
    productPickerShow,
    setProductPickerShow,
    getInclusionCollectionProducts,
    collectionPickerVisible,
    setCollectionPickerVisible
  } = props;

  const [inclusionCollectionPickerVisible, setInclusionCollectionPickerVisible] = useState(false);

  return (
    <ProductPicker>
      <div className="mockInputContainer">
        <div>
          <div
            className={!inclusionOption.value ? 'mockInput mockInputContainer-error' : 'mockInput'}
            onClick={() => {
              setProductPickerShow('collection');
              setInclusionCollectionPickerVisible(true)
            }}
          >
            {
              !inclusionOption.value
                ?
                  <span>Select collections</span>
                :
                  <div>
                    <span>{inclusionOption.value.length} collections</span>
                    <span> | Edit </span>
                  </div>
            }
          </div>
          {
            'collection' === productPickerShow
              ?
                <MulProductPickerModal
                  key={`${type}` + index}
                  host={host}
                  shop={shop}
                  token={token}
                  resourceType="Collection"
                  open={inclusionCollectionPickerVisible}
                  initialSelectionIds={inclusionOption.value ? inclusionOption.value : []}
                  showVariants={false}
                  onSelect={(selectPayload) => {
                    const collectionIDs = selectPayload.selection.map(item => item.id)
                    setProductIndex(index)
                    getInclusionCollectionProducts({variables: {ids: collectionIDs}})

                    if (selectPayload.selection.length === 0) {
                      if (inclusionOption.value) {
                        // Clear selection if one exists
                        setState(update(state, {shopBrain: {[type]: {[index]: {value: {$set: null}}}}}))
                      }
                      return null;
                    }

                  }}
                  onClose={() => {
                    setInclusionCollectionPickerVisible(false)
                    setProductPickerShow('product')
                  }}
                  type={type}
                />
              :
                false
          }
        </div>
      </div>
    </ProductPicker>
  );
};

export default CollectionModal;