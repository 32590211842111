import React, {useState, useCallback} from 'react'
import {
    TextField,
    Icon,
    Modal,
    OptionList,
    Spinner,
    TextContainer,
} from "@shopify/polaris"
import {
  SearchMajor
} from '@shopify/polaris-icons';
import update from 'immutability-helper'
import gql from "graphql-tag"
import { useLazyQuery } from 'react-apollo';
const GET_TAGS = gql`
   {
    shop{
      productTags(first: 250){
        edges{
          node
        }
      }
    }
  }
`
function TagModal(props) {
    const { saveExclusionValue, item, index } = props
    const [tagModalVisible, setTagModalVisible] = useState(false)
    const [getTags, tags] = useLazyQuery(GET_TAGS);
    const [selectedTag, setSelectedTag] = useState(item.value ? item.value : [])
    const [filledTag, setFilledTag] = useState([])
    const [searchTag, setSearchTag] = useState(null)
    const handleSearchTag = useCallback((newValue) => setSearchTag(newValue), []);
    const handleTagModalChange = () => {
        if( !tagModalVisible )
        {
            getTags()
        }
        setTagModalVisible(!tagModalVisible)
    }
    const saveTag = (index) => {
      saveExclusionValue(index, selectedTag.concat(filledTag))
      setTagModalVisible(false)
    }
    let searchedTags = [], difference = []
    if(tags.data){
        searchedTags = tags.data.shop.productTags.edges;
        if(searchTag){
            searchedTags = tags.data.shop.productTags.edges.filter(tag =>  tag.node.toLowerCase().match(searchTag.toLowerCase()))
        }
        difference = item.value ? item.value.filter(x => !tags.data.shop.productTags.edges.map(e => e.node).includes(x)) : [];
    }
    return (
                <Modal
                    activator={(<div className='mockInputContainer'>                                                        
                    <div className='mockInput'
                        onClick={handleTagModalChange}
                        >{!item.value?
                        <span>Select Tag</span>
                        :
                        <span>{item.value.length} tags | Edit</span>
                        }
                    </div>
                    </div>)}
                    open={tagModalVisible}
                    onClose={handleTagModalChange}
                    title="Select a product tag"
                    primaryAction={{
                    content: 'Save',
                    onAction: () => saveTag(index),
                    }}
                    secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleTagModalChange,
                    },
                    ]}
                >
                    <Modal.Section>
                    <TextContainer>
                        {(tags.loading && !tags.data) ?
                        <Spinner accessibilityLabel="Loading Tags" size="large" />
                        :
                        tags.data ?
                        <div>
                          <TextField
                            label="Search Tag"
                            labelHidden
                            prefix={<Icon
                                source={SearchMajor}
                                color="base" />}
                            autoComplete="off"
                            value={searchTag}
                            onChange={handleSearchTag}
                            />
                            <OptionList
                                title="Available Tags"
                                onChange={setSelectedTag}
                                options={
                                   searchedTags.map(tag => 
                                {return {value: tag.node, label: tag.node}}
                                ).concat(difference.map(tag =>{return {value: tag, label: tag}}))
                                }
                                selected={selectedTag.concat(filledTag)}
                                allowMultiple
                            />
                            <TextField 
                            label="manual filled tags" 
                            placeholder="Fill in tags here if you can’t find it in the list, separate with commas" 
                            labelHidden
                            onChange={(val) => setFilledTag(val ? val.split(","):[] ) }
                            value={filledTag.join(",")}
                            />
                        </div>
                        :
                        false
                        }
                    </TextContainer>
                    </Modal.Section>
                </Modal>        
        
    )
}

export default TagModal;